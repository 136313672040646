<template>
  <!-- begin:: Header -->
  <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
  >
    <div
        class="container-fluid d-flex align-items-center justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
      >
        <div
            v-if="true"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
        >
          <b-dropdown variant="light" >
            <template v-slot:button-content>
              <span class="">
                <i class="mr-2 fas fa-home"></i>{{ filialSelecionada }}
              </span>
            </template>
            <b-dropdown-item
                v-for="(filial, index) in lista_filiais_autorizadas"
                href="#"
                :key="index"
                @click="updateFilial(filial.id)">
              • {{ filial.filial }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <!-- end:: Header Menu -->

      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import {mapGetters} from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import FilialService from "@/core/services/filial.service";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
        this.$refs["kt_header_menu"],
        this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    lista_filiais_autorizadas() {
      return this.$store.state.sistema.lista_filiais_autorizadas;
    },

    filialSelecionada() {
      let filial_id = this.$store.state.sistema.id_filial_selecionada
      if (!this.lista_filiais_autorizadas || !filial_id) return 'Selecionar filial'
      return this.lista_filiais_autorizadas[filial_id].filial
    },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  },
  methods: {
    async updateFilial(value) {
      await this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("sistema/setFilial", value);
      await this.$store.dispatch("sistema/listar_permissoes_sede", value);
      // await this.$store.dispatch("configEmpresa/MudarPreloader", false);

      window.location.reload()
    },
  }
};
</script>
