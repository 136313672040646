<template>
  <div
    class="subheader py-2 py-lg-4"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="
        d-flex
        align-items-center
        justify-content-between
        flex-wrap flex-sm-nowrap
      "
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-inline-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ breadcrumbs.length && breadcrumbs[0].title }}
        </h5>
        <ul
          v-if="false"
          class="
            breadcrumb breadcrumb-transparent breadcrumb-dot
            font-weight-bold
            p-0
            my-2
          "
        >
          <li class="breadcrumb-item d-inline-flex">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li
              class="breadcrumb-item d-inline-flex"
              :key="`${i}-${breadcrumb.id}`"
            >
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <div v-for="(item, key) in subMenu" :key="key">
          <div v-if="title == item.title">
            <b-button-group v-for="(botoes, key) in item.botoes" :key="key">
              <router-link :to="botoes.route" v-slot="{ navigate }" 
              v-if="lista_permissoes_filial[botoes.permissao]">
                <b-button
                  @click="navigate"
                  class="btn btn-light font-weight-bold btn-sm"
                  variant="default"
                  style="color: black"
                >
                  {{ botoes.title }}
                </b-button>
              </router-link>
            </b-button-group>
          </div>
        </div>
    
      </div>


    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import menus from "@/core/router/subMenu";
export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  data() {
    return {
      subMenu: menus,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    lista_filiais_autorizadas() {
      return this.$store.state.sistema.lista_filiais_autorizadas;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
  // methods: {
  //    guardarDados(value) {
  //     this.$router.push({ name: "createGradeMatriculas" });
  //     this.$store.dispatch("guardarDados", value);
  //   },
  // }
};
</script>
