export default [
  {
    title: "Página Inicial",
    route: "/dashboard",
    permissao: "paginainicial",
    iconClass: "fas fa-chalkboard-teacher",
  },
  {
    title: "Gerenciamento",
    route: null,
    permissao: "ra_Empresa",
    iconClass: "fas fa-cogs",
    subItems: [
      {
        title: "Permissões",
        route: "/permissoes",
        permissao: "ra_Role",
      },

      {
        title: "Empresa",
        route: "/empresa",
        permissao: "ra_Empresa",
      },
      {
        title: "Filial",
        route: "/filial",
        permissao: "ra_Filial",
      },

      {
        title: "Perfil",
        route: "/perfil",
        permissao: "ra_Perfil",
      },
    ],
  },

  {
    title: "RH",
    route: null,
    permissao: "ra_Usuario",
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "Funcionários",
        route: "/gerenFuncionarios",
        permissao: "c_Usuario",
      },
    ],
  },




  {
    title: 'Financeiro',
    route: null,
    permissao: "ra_FinanCaixa",
    iconClass: 'fas fa-dollar-sign',
    subItems: [

      {
        title: 'Moeda',
        route: '/moeda',
        permissao: 'ra_FinanMoeda'
      },
      {
        title: 'Fornecedor',
        route: '/fornecedor',
        permissao: 'ra_Fornecedor'
      },
      {
        title: 'Produtos',
        route: '/produto',
        permissao: 'ra_Produto'
      },
      {
        title: 'Tipo Produto',
        route: '/tipoProduto',
        permissao: 'ra_TipoProduto'
      },
      {
        title: 'Modos de pagamento',
        route: '/modoPagamento',
        permissao: 'ra_FinanModoPagamento'
      },

      {
        title: 'Cambios',
        route: '/cambio',
        permissao: 'ra_FinanCambio'
      },
      {
        title: 'Caixa',
        route: '/caixa',
        permissao: 'ra_FinanCaixa'
      },



      {
        title: 'Formas de Pagamento',
        route: '/formaPagamento',
        permissao: 'ra_FinanFormaPagamento'

      },
      // {
      //     title: 'Pedidos de Compra',
      //     route: '/pedidoCompra',
      //     permissao:'ra_FinanPedidoCompra'
      // },
      // {
      //     title: 'Pedidos de Recebimento',
      //     route: '/finanPedidoRecebimento',
      //     permissao:'ra_FinanPedidoRecebimento'
      // },
      // {
      //     title: 'Contas a Pagar',
      //     route: '/finanContasPagar',
      //     permissao:'ra_FinanContasPagar'
      // },
      // {
      //     title: 'Contas a Receber',
      //     route: '/finanContasReceber',
      //     permissao:'ra_FinanContasReceber'
      // },


    ]
  },

  {
    title: 'Vendas',
    route: null,
    permissao: "ra_FinanPedidosReceber",
    iconClass: 'fas fa-dollar-sign',
    subItems: [

      {
        title: 'Venda',
        route: '/venda',
        permissao: 'ra_FinanPedidosReceber'
      },
      {
            title: 'Pedidos a receber',
          route: '/finanPedidoRecebimento',
          permissao:'ra_FinanContasReceber'
      },
      {
        title: 'Pedidos Em Espera',
        route: '/pedidosEspera',
        permissao: 'ra_FinanPedidosReceber'
      }
    ],
  },

  {
    title: "Cliente",
    route: null,
    permissao: "ra_Aviso",
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "Avisos",
        route: "/avisos",
        permissao: "ra_Aviso",
      },





    ],
  },
];
