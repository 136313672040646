export default [

    {
        title: "Perfil",
        botoes: [
            {
                title: "Criar",
                route: "/createPerfil",
                permissao: "c_Perfil",
            },
            {
                title: "Listar",
                route: "/perfil",
                permissao: "ra_Perfil",
            },
        ],
    },

    {
        title: "Permissoes",
        botoes: [
            {
                title: "Criar",
                route: "/createRule",
                permissao: "c_Role",
            },
            {
                title: "Listar",
                route: "/permissoes",
                permissao: "ra_Role",
            },
        ]
    },
    {
        title: "Empresa",
        botoes: [
            {
                title: "Criar",
                route: "/createEmpresa",
                permissao: "ra_empresa",
            },
            {
                title: "Listar",
                route: "/empresa",
                permissao: "ra_empresa",
            },
        ]
    },
    {
        title: "Filial",
        botoes: [
            {
                title: "Criar",
                route: "/createFilial",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/filial",
                permissao: "ra_Filial",
            },
        ]
    },

    {
        title: "Funcionarios",
        botoes: [
            {
                title: "Criar",
                route: "/createFuncionario",
                permissao: "ra_Usuario",
            },
            {
                title: "Listar",
                route: "/gerenFuncionarios",
                permissao: "ra_Usuario",
            },
        ],
    },

    {
        title: "Moeda",
        botoes: [
            {
                title: "Criar",
                route: "/createMoeda",
                permissao: "c_FinanMoeda",
            },
            {
                title: "Listar",
                route: "/moeda",
                permissao: "ra_FinanMoeda",
            },
        ],
    },
    {
        title: "Cambio",
        botoes: [
            {
                title: "Criar",
                route: "/createCambio",
                permissao: "c_FinanCambio",
            },
            {
                title: "Listar",
                route: "/cambio",
                permissao: "ra_FinanCambio",
            },
        ],
    },

    {
        title: "Caixa",
        botoes: [
            {
                title: "Criar",
                route: "/createCaixa",
                permissao: "c_FinanCaixa",
            },
            {
                title: "Listar",
                route: "/caixa",
                permissao: "ra_FinanCaixa",
            },
        ],
    },

    {
        title: "Fornecedor",
        botoes: [
            {
                title: "Criar",
                route: "/createFornecedor",
                permissao: "c_Fornecedor",
            },
            {
                title: "Listar",
                route: "/fornecedor",
                permissao: "ra_Fornecedor",
            },
        ],
    },


    {
        title: "Produtos",
        botoes: [
            {
                title: "Criar",
                route: "/createProduto",
                permissao: "c_Produto",
            },
            {
                title: "Listar",
                route: "/produto",
                permissao: "ra_Produto",
            },
        ],
    },

    {
        title: "Tipos de Produtos",
        botoes: [
            {
                title: "Criar",
                route: "/createTipoProduto",
                permissao: "c_TipoProduto",
            },
            {
                title: "Listar",
                route: "/tipoProduto",
                permissao: "ra_TipoProduto",
            },
        ],
    },

    {
        title: "Modos de Pagamento",
        botoes: [
            {
                title: "Criar",
                route: "/createModoPagamento",
                permissao: "c_FinanModoPagamento",
            },
            {
                title: "Listar",
                route: "/modoPagamento",
                permissao: "ra_FinanModoPagamento",
            },
        ],
    },

    {
        title: "Formas de Pagamento",
        botoes: [
            {
                title: "Criar",
                route: "/createFormaPagamento",
                permissao: "c_FinanFormaPagamento",
            },
            {
                title: "Listar",
                route: "/formaPagamento",
                permissao: "ra_FinanFormaPagamento",
            },
        ],
    },

    {
        title: "Vendas",
        botoes: [           
            {
                title: "Listar",
                route: "/pedidosEspera",
                permissao: "ra_FinanPedidosReceber",
            },
            {
                title: "Criar",
                route: "/venda",
                permissao: "ra_FinanPedidosReceber",
            },
        ],
    },

    {
        title: "Pedidos",
        botoes: [
            {
                title: "Listar",
                route: "/pedidosEspera",
                permissao: "ra_FinanPedidosReceber",
            },
            
            {
                title: "Criar",
                route: "/venda",
                permissao: "ra_FinanPedidosReceber",
            },
        ],
    },



];
